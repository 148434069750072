import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ユーザー認証と OpenID Connect",
  "date": "2019-04-26T08:09:46.000Z",
  "slug": "entry/2019/04/26/170946",
  "tags": ["medley"],
  "hero": "./2019_04_26.png",
  "heroAlt": "openid"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部のエンジニアの鶴です。 今回は先月に行った社内の勉強会 TechLunch の内容をご紹介させていただきます。`}</p>
    <h1>{`イントロ`}</h1>
    <p>{`Web サービスでは、ユーザーにアカウントを作ってもらい、ログインをしてサービスを利用してもらう、というユーザー認証を利用するサービスも多いかと思います。 Web サービスを開発する側としては、サービスごとに都度ユーザー認証の仕組みを構築する必要がありますが、セキュリティ対策の観点から考慮することが多く、地味に開発の工数がかかってしまいます。`}</p>
    <p>{`また最近では、`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/cognito/"
      }}>{`Amazon Cognito`}</a>{`や`}<a parentName="p" {...{
        "href": "https://firebase.google.com/products/auth/?hl=ja"
      }}>{`Firebase Authentication`}</a>{`、`}<a parentName="p" {...{
        "href": "https://auth0.com/jp/"
      }}>{`Auth0`}</a>{`など、ユーザー認証サービスがいくつかリリースされ、ユーザー認証の機能をこれらの外部サービスに任せて開発の手間を省くという選択肢も取れるようになってきています。 自分自身、かつて担当したプロジェクトでユーザー認証の仕組みを Amazon Cognito にまかせてシステムを構築したことがありました。`}</p>
    <p>{`しかし、当時は特にユーザープールの機能がリリースされて間もないこともあり、SDK の動作やサービスの仕様の理解にかなり手間取ったことを覚えています。`}</p>
    <p>{`ユーザー認証サービスでは`}<a parentName="p" {...{
        "href": "https://openid.net/connect/"
      }}>{`OpenID Connect`}</a>{`という仕様に準拠していることが多いのですが、おそらく自分にとってこの仕様の理解が疎かだったことが原因の一つだったと思います。`}</p>
    <p>{`そこで今回は、ユーザー認証と OpenID Connect の仕組みについて改めて勉強し直したので、その内容を簡単に解説をさせていただこうと思います。`}</p>
    <h1>{`ユーザー認証とは`}</h1>
    <p>{`ユーザー認証の前に、そもそも認証とはどういう操作のことを指すのでしょうか。 みんな大好き`}<a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/%E8%AA%8D%E8%A8%BC"
      }}>{`Wikipedia 先生`}</a>{`によると、以下のような記載があります。`}</p>
    <blockquote>
      <p parentName="blockquote">{`認証（にんしょう）とは、何かによって、対象の正当性を確認する行為を指す。`}{` `}{`認証行為は認証対象よって分類され、認証対象が人間である場合には相手認証（本人認証）、メッセージである場合にはメッセージ認証、時刻の場合には時刻認証と呼ぶ。 単に認証と言った場合には相手認証を指す場合が多い。`}</p>
    </blockquote>
    <p>{`ユーザー認証は Web サービスにとってリクエストを送信してきた相手の正当性を認証することなので、相手認証の 1 つですね。 さらに相手認証の認証方法として 2 通りの方法があります。`}</p>
    <blockquote>
      <p parentName="blockquote">{`第 1 の方法は、被認証者が認証者に、秘密鍵をもっていることによって得られる何らかの能力の証明を行う方法である。第 2 の方法は、被認証者が認証者に、被認証者の公開鍵に対応する秘密鍵の知識の証明を行う方法である。`}</p>
    </blockquote>
    <p>{`ユーザー認証の場合、多くはこの第 1 の方法での認証で、ログイン時にユーザー ID に加えて、この「秘密鍵」としてアカウント作成時に登録しておいたパスワードを入力することでユーザー認証を行っているかと思います。`}</p>
    <h1>{`Web サービスでのユーザー認証`}</h1>
    <p>{`Web サービスで扱う情報の秘匿性が高くなればなるほど、この「秘密鍵」が本当にそのユーザーにしか提供できない情報であることが求められます。`}</p>
    <p>{`上述のようなパスワードによる認証の場合、パスワードが推測されるなどして悪意のある第三者にアカウントが乗っ取られてしまう事件はよく耳にします。`}</p>
    <p>{`よりセキュリティを高めるため、パスワード以外の認証や多要素認証などを用いる事が増えてきました。`}</p>
    <p>{`また、セキュリティの観点だけでなく利便性の観点からも、パスワード入力の代わりに指紋認証や顔認証によるログインや、あるいは各種 SNS アカウントによるログインも増えてきています。自社の複数のサービスを連携できるようユーザーに共通 ID を提供したい、といったケースもあるかもしれません。`}</p>
    <p>{`最近ではパスワードレス認証や`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/webauthn/"
      }}>{`WebAuthn`}</a>{`も注目されていますね。今回は紹介は割愛しますが、パスワードレス認証の一つである`}<a parentName="p" {...{
        "href": "https://fidoalliance.org/fido%E3%81%AE%E4%BB%95%E7%B5%84%E3%81%BF/?lang=ja"
      }}>{`FIDO 認証`}</a>{`は、前述の「被認証者が認証者に、被認証者の公開鍵に対応する秘密鍵の知識の証明を行う方法」を利用した認証方式のようです。（`}<a parentName="p" {...{
        "href": "https://fidoalliance.org/"
      }}>{`ref1`}</a>{`,`}<a parentName="p" {...{
        "href": "https://www.slideshare.net/techblogyahoo/fido-124019677"
      }}>{` ref2`}</a>{`）`}</p>
    <p>{`このように、セキュリティの観点やユーザー利便性の観点などにより、Web サービスにおけるユーザー認証機能は 1 回作ったら終わりではなく、時流に応じて適宜改修する必要が出てくることもあるかと思います。`}</p>
    <p>{`しかし、特にユーザー認証がメインのサービスと密結合している場合などでは、認証の前後など認証処理そのものだけでなくその周辺の処理への影響範囲も無視できない場合もあり、ユーザー認証の改修に工数が思ったよりかかってしまったり、対応が滞ってしまうこともあるかもしれません。`}</p>
    <p>{`そんなとき、認証サービスをメインのサービスと切り離すことでより柔軟なユーザー認証手段を提供できるよう、OpenID Connect の導入を検討してみても良いかもしれません。`}</p>
    <h1>{`OpenID Connect とは`}</h1>
    <p>{`OpenID Connect(以降、OIDC)について、本家サイトでは以下のように説明されています。`}</p>
    <blockquote>
      <p parentName="blockquote">{`OpenID Connect 1.0 は, OAuth 2.0 プロトコルの上にシンプルなアイデンティティレイヤーを付与したものである. このプロトコルは Client が Authorization Server の認証結果に基づいて End-User のアイデンティティを検証可能にする. また同時に End-User の必要最低限のプロフィール情報を, 相互運用可能かつ RESTful な形で取得することも可能にする.`}</p>
      <p parentName="blockquote">{`この仕様は, OpenID Connect の主要な機能である OAuth 2.0 上で End-User の情報伝達のためにクレームを用いる認証機能を定義する. この仕様はまた, OpenID Connect を利用するための Security, Privacy Considerations を説明する.`}</p>
    </blockquote>
    <p>{`（`}<a parentName="p" {...{
        "href": "https://www.openid.or.jp/document/index.html#op-doc-openid-connect"
      }}>{`日本語`}</a>{`,`}<a parentName="p" {...{
        "href": "https://openid.net/connect/"
      }}>{` 英語`}</a>{`）`}</p>
    <p>{`個人的には、メインのサービスと認証サービスを切り離して運用することを想定して仕様が規定されている点が重要と考えます。 OIDC を利用することで、ユーザー認証をより柔軟に改修したり新しい認証方法に対応したりすることがしやすくなることが期待されるからです。`}</p>
    <p>{`なお、OIDC の仕様には認証手段自体（パスワード認証や多要素認証など）に関しては規定されておらず、あくまで認証サービスによる認証結果の取得方法や扱い方についてが規定されています。`}</p>
    <p>{`また、様々なユースケースに対応できるよういくつかの処理フローやオプショナルな設定が提供されていますが、その反面セキュリティの確保は実装者に委ねられており、ユースケースに応じて適切な実装を行う必要があります。`}</p>
    <p>{`前述したユーザー認証サービスである Amazon Cognito や FirebaseAuthentication などは、認証手段が標準でいくつか提供されており、加えてバックエンドと SDK に OIDC 固有のセキュアな実装が施されてあるため、開発者は最小限の設定だけでユーザー認証機能が利用できるようになります。便利ですね。`}</p>
    <h1>{`処理フローの解説`}</h1>
    <p>{`さて、OIDC の具体的な処理について解説していこうと思います。`}</p>
    <p>{`まず登場人物です。`}</p>
    <ul>
      <li parentName="ul">{`OpenID Provider（OP）：認証認可を行うサービス。ユーザー認証情報（識別子やパスワードなど）を管理したり、認証に関するユーザー属性情報（氏名やユーザー名など）を保持する。`}</li>
      <li parentName="ul">{`RelyingParty（RP）： アクセス元のユーザーの認証とユーザー属性情報を要求するサービス。ユーザーからのリクエストに対し OP による認証結果を信頼（rely）してリソースへのアクセスを許可する（例えばマイページを表示するなど）。`}</li>
      <li parentName="ul">{`EndUser：ログインをしてサービスを利用しようとしているユーザー。`}</li>
    </ul>
    <p>{`基本的な用語も先に簡単に紹介しておきます。`}</p>
    <ul>
      <li parentName="ul">{`クライアント ID：OpenID Provider で管理する、RelyingParty の識別情報。`}</li>
      <li parentName="ul">{`クライアントシークレット：OpenID Provider が RelyingParty ごとに発行する秘密鍵。`}</li>
      <li parentName="ul">{`認証コード：後述する AuthorizationCodeFlow で OpenID Provider が発行する短命のパスワードのようなもの。`}</li>
      <li parentName="ul">{`ID トークン：OpenID Provider から発行される、ユーザーによる認証を行った証明情報。`}<a parentName="li" {...{
          "href": "https://jwt.io/"
        }}>{`JSON Web Token(JWT)`}</a>{`で表現され、検証により改ざん検知することができる。認証の内容（OpenID Provider、ユーザー識別子、RelyingParty のクライアント ID、有効期限など）やユーザー属性情報が格納される。`}</li>
      <li parentName="ul">{`アクセストークン:OpenID Provider が保持するユーザー属性情報に対しアクセスするための OAuth2 の認可トークン。`}</li>
    </ul>
    <p>{`OIDC の処理フローは大きく分けて 3 種類が規定されています。`}</p>
    <ul>
      <li parentName="ul">{`AuthorizationCodeFlow：認証成功時に OpenID Provider が RelyingParty に対し認証コードを発行し、RelyingParty はこれを用いて OpenID Provider から ID トークン等を取得する。RelyingParty がサーバーサイドアプリケーションで、OpenID Provider から発行されるクライアントシークレットを安全に管理することができる場合などに用いられる。`}</li>
      <li parentName="ul">{`ImplicitFlow：認証コードを使わず認証結果のレスポンスで ID トークン等を取得する。RelyingParty がクライアントアプリケーションの場合など、クライアントシークレットが安全に管理できない場合などに用いられる。`}</li>
      <li parentName="ul">{`HybridFlow：AuthorizationCodeFlow と ImplicitFlow の組み合わせ。`}</li>
    </ul>
    <p>{`これらのフローの違いは以下の表のとおりです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426141901.png",
        "alt": "f:id:medley_inc:20190426141901p:plain",
        "title": "f:id:medley_inc:20190426141901p:plain"
      }}></img></p>
    <p>{`（`}<a parentName="p" {...{
        "href": "https://openid-foundation-japan.github.io/openid-connect-core-1_0.ja.html#Authentication"
      }}>{`公式より引用`}</a>{`）`}</p>
    <p>{`今回は、`}<a parentName="p" {...{
        "href": "https://openid-foundation-japan.github.io/openid-connect-basic-1_0.ja.html"
      }}>{`公式`}</a>{`や`}<a parentName="p" {...{
        "href": "https://www.slideshare.net/mobile/kura_lab/openid-connect-id"
      }}>{`こちらの解説記事`}</a>{`などを参照しながら、基本の処理フローである AuthorizationCodeFlow について解説します。`}</p>
    <p>{`簡略化のため、イメージ重視で登場人物は「`}<em parentName="p">{`ユーザー`}</em>{`」「（ユーザーにサービスを提供する）`}<em parentName="p">{`Web サービス`}</em>{`」「`}<em parentName="p">{`認証サービス`}</em>{`」と表現することにします。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426141939.png",
        "alt": "f:id:medley_inc:20190426141939p:plain",
        "title": "f:id:medley_inc:20190426141939p:plain"
      }}></img></p>
    <p>{`大まかには以下のステップで処理が行われます。`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`ユーザー`}</em>{`からのアクセスに対し、`}<em parentName="li">{`Web サービス`}</em>{`は`}<em parentName="li">{`認証サービス`}</em>{`にユーザー認証を要求する`}</li>
      <li parentName="ol"><em parentName="li">{`認証サービス`}</em>{`はユーザー認証を行い、認証コードを発行して、`}<em parentName="li">{`ユーザー`}</em>{`を`}<em parentName="li">{`Web サービス`}</em>{`にリダイレクトさせる`}</li>
      <li parentName="ol"><em parentName="li">{`Web サービス`}</em>{`は 2 で取得した認証コードを用いて`}<em parentName="li">{`認証サービス`}</em>{`に ID トークン等をリクエストする`}</li>
      <li parentName="ol"><em parentName="li">{`Web サービス`}</em>{`は 3 で取得した ID トークンを検証し、`}<em parentName="li">{`ユーザー`}</em>{`の識別子を取得する`}</li>
    </ol>
    <h2>{`Step.0 : 事前準備`}</h2>
    <p>{`あらかじめ`}<em parentName="p">{`Web サービス`}</em>{`は`}<em parentName="p">{`認証サービス`}</em>{`からクライアント ID とクライアントシークレットを取得し保持しておきます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426142007.png",
        "alt": "f:id:medley_inc:20190426142007p:plain",
        "title": "f:id:medley_inc:20190426142007p:plain"
      }}></img></p>
    <h2>{`Step.1: ユーザー認証の要求`}</h2>
    <p><em parentName="p">{`ユーザー`}</em>{`が`}<em parentName="p">{`Web サービス`}</em>{`に対し一般的なログインの流れでログインを要求すると、`}<em parentName="p">{`Web サービス`}</em>{`は`}<em parentName="p">{`認証サービス`}</em>{`にリクエストをリダイレクトします。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426142502.png",
        "alt": "f:id:medley_inc:20190426142502p:plain",
        "title": "f:id:medley_inc:20190426142502p:plain"
      }}></img></p>
    <p><em parentName="p">{`Web サービス`}</em>{`から`}<em parentName="p">{`認証サービス`}</em>{`へのリダイレクトの URL は以下のような感じです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "http"
    }}><pre parentName="div" {...{
        "className": "language-http"
      }}><code parentName="pre" {...{
          "className": "language-http"
        }}><span parentName="code" {...{
            "className": "token response-status"
          }}><span parentName="span" {...{
              "className": "token http-version property"
            }}>{`HTTP/1.1`}</span>{` `}<span parentName="span" {...{
              "className": "token status-code number"
            }}>{`302`}</span>{` `}<span parentName="span" {...{
              "className": "token reason-phrase string"
            }}>{`Found`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Location`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`https://server.example.com/authorize?
   response_type=code
   &client_id=s6BhdRkqt3
   &redirect_uri=https%3A%2F%2Fclient.example.org%2Fcb
   &scope=openid%20profile
   &state=af0ifjsldkj`}</span></span></code></pre></div>
    <p>{`response_type で OIDC のどの認証フローを使うかを指定します。`}</p>
    <p>{`redirect_uri は、`}<em parentName="p">{`認証サービス`}</em>{`での認証が成功したときの`}<em parentName="p">{`Web サービス`}</em>{`にコールバックする URL です。これは事前に認証サービスに登録しておく必要があります。`}</p>
    <p>{`scope には認証の内容を設定します。openid は必須で、他には OAuth2 のアクセストークンを使って取得できるユーザー属性情報を指定します。`}</p>
    <p>{`scope で指定できるユーザー属性情報は以下のとおりです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426142232.png",
        "alt": "f:id:medley_inc:20190426142232p:plain",
        "title": "f:id:medley_inc:20190426142232p:plain"
      }}></img></p>
    <p>{`（`}<a parentName="p" {...{
        "href": "https://openid-foundation-japan.github.io/openid-connect-core-1_0.ja.html#StandardClaims"
      }}>{`公式より引用`}</a>{`）`}</p>
    <p>{`ユーザーの認証でよく使われそうな「氏名」や「メールアドレス」など基本的な属性情報が定義されています。`}</p>
    <p>{`state は CSRF 対策などのためのランダム値です。認証フローを開始するたびに`}<em parentName="p">{`Web サービス`}</em>{`が発行し、リクエストとコールバックの間で値が維持されます。`}</p>
    <p>{`他にもいくつかのパラメータ（nonce など）が定義されており、必要に応じて利用します。`}</p>
    <h2>{`Step.2: ユーザー認証と認証コードの発行`}</h2>
    <p><em parentName="p">{`認証サービス`}</em>{`では認証手段に応じてログイン ID ・パスワードの入力フォームなどを表示し、`}<em parentName="p">{`ユーザー`}</em>{`から認証情報を取得して認証処理を行います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426142212.png",
        "alt": "f:id:medley_inc:20190426142212p:plain",
        "title": "f:id:medley_inc:20190426142212p:plain"
      }}></img></p>
    <p><em parentName="p">{`認証サービス`}</em>{`はユーザーの認証に成功すると、認証コードを発行し、`}<em parentName="p">{`ユーザー`}</em>{`を`}<em parentName="p">{`Web サービス`}</em>{`にリダイレクトさせます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "http"
    }}><pre parentName="div" {...{
        "className": "language-http"
      }}><code parentName="pre" {...{
          "className": "language-http"
        }}><span parentName="code" {...{
            "className": "token response-status"
          }}><span parentName="span" {...{
              "className": "token http-version property"
            }}>{`HTTP/1.1`}</span>{` `}<span parentName="span" {...{
              "className": "token status-code number"
            }}>{`302`}</span>{` `}<span parentName="span" {...{
              "className": "token reason-phrase string"
            }}>{`Found`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Location`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`https://client.example.org/cb?
   code=SplxlOBeZQQYbYS6WxSbIA
   &state=af0ifjsldkj`}</span></span></code></pre></div>
    <p>{`リダイレクト先について、`}<em parentName="p">{`認証サービス`}</em>{`は Step.1 で受け取った redirect`}<em parentName="p">{`url を`}</em>{`認証サービス`}<em parentName="p">{`に予め登録されている URL と合致することを検証する必要があります。`}{`_`}{`Web サービス`}</em>{`のなりすましを防ぐためです。`}</p>
    <p>{`また`}<em parentName="p">{`Web サービス`}</em>{`側で`}<em parentName="p">{`認証サービス`}</em>{`からのレスポンスであることを確認できるよう、state もパラメータに含めます。`}</p>
    <p>{`なお、認証に失敗した場合は下記のように認証エラーした内容をパラメーターに加えて Web サービスにリダイレクトさせます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "http"
    }}><pre parentName="div" {...{
        "className": "language-http"
      }}><code parentName="pre" {...{
          "className": "language-http"
        }}><span parentName="code" {...{
            "className": "token response-status"
          }}><span parentName="span" {...{
              "className": "token http-version property"
            }}>{`HTTP/1.1`}</span>{` `}<span parentName="span" {...{
              "className": "token status-code number"
            }}>{`302`}</span>{` `}<span parentName="span" {...{
              "className": "token reason-phrase string"
            }}>{`Found`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Location`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`https://client.example.org/cb?
   error=invalid_request
   &error_description=
     Unsupported%20response_type%20value
   &state=af0ifjsldkj`}</span></span></code></pre></div>
    <h2>{`Step.3: 認証結果の取得`}</h2>
    <p>{`Step.2 で`}<em parentName="p">{`Web サービス`}</em>{`は`}<em parentName="p">{`認証サービス`}</em>{`からのリダイレクトを受け、認証コードを取得すると、この認証コードを利用して`}<em parentName="p">{`認証サービス`}</em>{`に対して認証結果情報（ID トークンなど）を取得します。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190426/20190426142612.png",
        "alt": "f:id:medley_inc:20190426142612p:plain",
        "title": "f:id:medley_inc:20190426142612p:plain"
      }}></img></p>
    <p><em parentName="p">{`Web サービス`}</em>{`から`}<em parentName="p">{`認証サービス`}</em>{`への認証結果取得リクエストは以下のような形式になります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "http"
    }}><pre parentName="div" {...{
        "className": "language-http"
      }}><code parentName="pre" {...{
          "className": "language-http"
        }}><span parentName="code" {...{
            "className": "token request-line"
          }}><span parentName="span" {...{
              "className": "token method property"
            }}>{`POST`}</span>{` `}<span parentName="span" {...{
              "className": "token request-target url"
            }}>{`/token`}</span>{` `}<span parentName="span" {...{
              "className": "token http-version property"
            }}>{`HTTP/1.1`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Host`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`server.example.com`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Authorization`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`Basic czZCaGRSa3F0MzpnWDFmQmF0M2JW`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Content-Type`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`application/x-www-form-urlencoded`}</span></span>{`

grant_type=authorization_code&code=SplxlOBeZQQYbYS6WxSbIA
  &redirect_uri=https%3A%2F%2Fclient%2Eexample%2Ecom%2Fcb`}</code></pre></div>
    <p>{`認証コードを送信する必要があるため、POST メソッドでリクエストします。またクライアント ID とクライアントシークレットによる BASIC 認証を行います。`}</p>
    <p>{`このリクエストでクライアントシークレットが必要になるのですが、これは`}<em parentName="p">{`認証サービス`}</em>{`にとって`}<em parentName="p">{`Web サービス`}</em>{`の正当性を検証するための重要なパラメータであり、安全に管理される必要があります。`}</p>
    <p>{`SinglePageApplication のようにユーザー側にあるアプリケーションで OIDC を処理する場合には、このクライアントシークレットが安全に管理される保証がないため、AuthenticationCodeFlow ではなく`}<a parentName="p" {...{
        "href": "https://openid-foundation-japan.github.io/openid-connect-core-1_0.ja.html#ImplicitFlowAuth"
      }}>{`ImplicitFlow`}</a>{`などを利用する必要があります。`}</p>
    <p><em parentName="p">{`Web サービス`}</em>{`からのリクエストを受け取った`}<em parentName="p">{`認証サービス`}</em>{`は grant_type に Step.1 で指定した処理フローに該当する情報を渡し、認証コード（ code ）と合わせて`}<em parentName="p">{`認証サービス`}</em>{`にリクエストの検証をさせます。`}</p>
    <p><em parentName="p">{`認証サービス`}</em>{`はリクエストの検証に成功すると、`}<em parentName="p">{`Web サービス`}</em>{`に対し認証結果として ID トークン等を返却します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "http"
    }}><pre parentName="div" {...{
        "className": "language-http"
      }}><code parentName="pre" {...{
          "className": "language-http"
        }}><span parentName="code" {...{
            "className": "token response-status"
          }}><span parentName="span" {...{
              "className": "token http-version property"
            }}>{`HTTP/1.1`}</span>{` `}<span parentName="span" {...{
              "className": "token status-code number"
            }}>{`200`}</span>{` `}<span parentName="span" {...{
              "className": "token reason-phrase string"
            }}>{`OK`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Content-Type`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`application/json`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Cache-Control`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`no-cache, no-store`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Pragma`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`no-cache
 {
  "access_token":"SlAV32hkKG",
  "token_type":"Bearer",
  "expires_in":3600,
  "refresh_token":"tGzv3JOkF0XG5Qx2TlKWIA",
  "id_token":"eyJ0 ... NiJ9.eyJ1c ... I6IjIifX0.DeWt4Qu ... ZXso"
 }`}</span></span></code></pre></div>
    <p>{`access_token は`}<em parentName="p">{`認証サービス`}</em>{`で管理しているユーザー属性情報を取得するための OAuth2 トークンです。`}</p>
    <p>{`refresh_token は`}<em parentName="p">{`認証サービス`}</em>{`から access_token を再発行する際に利用します。`}</p>
    <h2>{`Step.4: 認証結果の検証`}</h2>
    <p>{`Web サービス`}<em parentName="p">{`は`}</em>{`認証サービス`}<em parentName="p">{`から取得した ID トークンを検証します。ID トークンは前述の通り JWT で表現されており、`}</em>{`認証サービス`}{`_`}{`の公開鍵を用いて検証することができます。`}</p>
    <p>{`手順は`}<a parentName="p" {...{
        "href": "https://openid-foundation-japan.github.io/openid-connect-basic-1_0.ja.html#IDTokenValidation"
      }}>{` こちら`}</a>{` をご確認ください。他にも参考リンクを紹介しておきます。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://qiita.com/bobunderson/items/d48f89e2b3e6ad9f9c4c"
        }}>{`https://qiita.com/bobunderson/items/d48f89e2b3e6ad9f9c4c`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://qiita.com/TakahikoKawasaki/items/8f0e422c7edd2d220e06"
        }}>{`https://qiita.com/TakahikoKawasaki/items/8f0e422c7edd2d220e06`}</a></li>
    </ul>
    <p>{`下記は ID トークンに含まれる認証情報の例です。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"iss"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://server.example.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sub"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"24400320"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"aud"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"s6BhdRkqt3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"exp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1311281970`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"iat"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1311280970`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`このうち sub が`}<em parentName="p">{`認証サービス`}</em>{`で管理されている`}<em parentName="p">{`ユーザー`}</em>{`の識別子です。`}</p>
    <p>{`iss は`}<em parentName="p">{`認証サービス`}</em>{`、aud は`}<em parentName="p">{`Web サービス`}</em>{`のクライアント ID になります。`}</p>
    <p>{`exp、iat はそれぞれ認証の有効期限と認証したタイムスタンプです。`}</p>
    <p><em parentName="p">{`Web サービス`}</em>{`は ID トークンが正しい内容であることが確認できれば、これをログインセッションと紐づけて保管します。`}</p>
    <p>{`以上で認証処理は完了です。`}</p>
    <h1>{`ユーザー属性情報の取得`}</h1>
    <p>{`ユーザー認証後、`}<em parentName="p">{`Web サービス`}</em>{`がユーザー名などのユーザー属性情報が必要になった場合、Step.3 で取得した access_token を利用し`}<em parentName="p">{`認証サービス`}</em>{`に対してユーザー属性情報をリクエストします。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "http"
    }}><pre parentName="div" {...{
        "className": "language-http"
      }}><code parentName="pre" {...{
          "className": "language-http"
        }}><span parentName="code" {...{
            "className": "token request-line"
          }}><span parentName="span" {...{
              "className": "token method property"
            }}>{`GET`}</span>{` `}<span parentName="span" {...{
              "className": "token request-target url"
            }}>{`/userinfo`}</span>{` `}<span parentName="span" {...{
              "className": "token http-version property"
            }}>{`HTTP/1.1`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Host`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`server.example.com`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token header"
          }}><span parentName="span" {...{
              "className": "token header-name keyword"
            }}>{`Authorization`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token header-value"
            }}>{`Bearer SlAV32hkKG`}</span></span></code></pre></div>
    <p>{`このリクエストにより、Step.1 の scope で指定したユーザー属性情報が取得できます。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`以上少し長くなりましたが、ユーザー認証と OpenID Connect、特に基本の AuthenticationCodeFlow について解説しました。限られた発表時間の中での解説のため厳密さより雰囲気を重視した内容となりましたが、お気づきの点などあればお知らせいただければと思います。`}</p>
    <p>{`サービスの要件やフェーズによって OIDC を取り入れるかどうかは様々ですが、ユーザー認証の実装を自前で実装、メンテナンスしていくだけでなく、Amazon Cognito などの便利な認証サービスを利用していくことも選択肢の一つとして検討してみても良いかもしれません。`}</p>
    <p>{`そしてそれら便利な認証サービスをうまく使いこなすためにも、その背景にある OIDC の仕様や思想、そもそも認証の仕組みについて立ち返ってみると、理解が一段と深まるかとおもいます。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CREATOR'S STORY | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fteam%2Fcreator-story.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/team/creator-story.html">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      